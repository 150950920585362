import styled from "styled-components";
import spacing from "styles/spacing";
import { TextH3, textLabelStyles, mFont, TextLabel } from "styles/typography";
import { clampLineStyles } from "styles/snippets";
import screenSize from "styles/mediaQueries";

export const ContentPreview = styled.div`
  flex: 1;
  padding: ${spacing.md};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const LinkWrapper = styled.a`
  text-decoration: none;
  color: ${(props) =>
    props.theme === "dark" ? "var(--color-light)" : "var(--color-text)"};

  :hover {
    color: ${(props) =>
      props.theme === "dark"
        ? "var(--color-lightAccent)"
        : "var(--color-darkAccent)"};
  }
`;

export const Title = styled(TextH3)`
  color: inherit;
  font-size: ${mFont};
  line-height: 1.6;
  margin-bottom: ${spacing.xs};
`;

interface DescriptionProps {
  hide?: boolean;
  collapsedOnMobile?: boolean;
}

export const Description = styled.div<DescriptionProps>`
  ${textLabelStyles}
  color: inherit;
  margin-bottom: ${spacing.md};

  ${(props) => (props.hide ? "display: none;" : "")}

  ${screenSize.mobileOnly`
    ${(props: DescriptionProps) =>
      props.collapsedOnMobile ? clampLineStyles(2) : ""}
    ${(props: DescriptionProps) =>
      props.collapsedOnMobile
        ? `margin-bottom: 0;`
        : `margin-bottom: ${spacing.sm};`}
  `}

  ${screenSize.minTablet`
    ${(props: DescriptionProps) =>
      props.hide ? "display: none;" : "display: block;"}
  `}
`;

export const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.xxs};
  bottom: 0;
  left: 0;
  margin-bottom: ${spacing.xs};
`;

export const DateText = styled.span`
  ${textLabelStyles}

  color: ${(props) => (props.theme === "dark" ? "var(--color-light);" : "")}
`;

export const BlogSeriesLabel = styled(TextLabel)`
  border-radius: 0 0 0 10px;
  color: var(--color-text);
  background-color: var(--color-lightShades);
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
