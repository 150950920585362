import React from "react";
import BadgeLink from "components/atoms/badges/BadgeLink";
import { formatDateStringShort } from "utils";
import { BlogSeries, Category } from "types/attheminute";
import Link from "next/link";
import Profile from "components/molecules/Profile";
import * as Styled from "./BasicContentPreview.styled";
import Badge from "components/atoms/badges/Badge";
import Tooltip from "components/ui/Tooltip/Tooltip";
import useClientDateFormat from "hooks/useClientDateFormat";
import { ThemeContext } from "styled-components";

export interface BasicContentPreviewProps {
  heading?: string;
  description?: string;
  authorName?: string;
  authorImage?: string;
  blogSeries?: BlogSeries;
  categories?: Category[];
  hideDescription?: boolean;
  className?: string;
  theme?: "light" | "dark";
  collapsedOnMobile?: boolean;
  firstPublishedAt?: string;
  fullSlug?: string;
}

export default function BasicContentPreview(props: BasicContentPreviewProps) {
  const {
    heading,
    description,
    authorName,
    authorImage,
    categories,
    hideDescription,
    className,
    theme,
    collapsedOnMobile,
    firstPublishedAt,
    fullSlug,
    blogSeries,
  } = props;

  const firstPublishedAtStr = useClientDateFormat(firstPublishedAt);

  return (
    <Styled.ContentPreview className={className}>
      {categories && categories.length ? (
        <Styled.Categories>
          {blogSeries ? (
            <Tooltip content={blogSeries.name}>
              <Badge textColourName="text" backgroundColourName="lightShades">
                🔗&nbsp;Blog series
              </Badge>
            </Tooltip>
          ) : null}
          {categories && categories.length > 0
            ? categories.map((category) => (
                <BadgeLink
                  to={`/${category.fullSlug}`}
                  key={category.id || category.fullSlug}
                  backgroundColourName={
                    category.colour ? category.colour : undefined
                  }
                  textColourName={
                    category.colour ? `contrast-${category.colour}` : undefined
                  }
                >
                  {category.name}
                </BadgeLink>
              ))
            : null}
        </Styled.Categories>
      ) : null}
      <Link href={`/${fullSlug}`} passHref legacyBehavior>
        <Styled.LinkWrapper theme={theme}>
          <Styled.Title noMarginTop>{heading}</Styled.Title>
          <Styled.Description
            hide={hideDescription}
            collapsedOnMobile={collapsedOnMobile}
          >
            {description}
          </Styled.Description>
        </Styled.LinkWrapper>
      </Link>
      <Styled.Footer>
        <Profile name={authorName} image={authorImage} theme={theme} />
        <Styled.DateText theme={theme}>{firstPublishedAtStr}</Styled.DateText>
      </Styled.Footer>
    </Styled.ContentPreview>
  );
}
