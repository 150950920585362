import * as React from "react";
import { formatDateStringShort } from "utils";

// Client + server can have different timezones, so only show the date on the client.
export default function useClientDateFormat(inputDateString?: string) {
  const [dateString, setDateString] = React.useState<string>("");

  React.useEffect(() => {
    if (inputDateString) {
      setDateString(formatDateStringShort(inputDateString));
    }
  }, [inputDateString]);

  return dateString;
}
