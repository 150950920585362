import styled from "styled-components";
import spacing from "styles/spacing";
import { textLabelStyles } from "styles/typography";

interface StyledAvatarProps {
  $hide?: boolean;
  $hideOnMobile?: boolean;
}

export const ProfileContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) =>
    props.theme === "dark" ? "var(--color-light)" : "var(--color-dark)"};
`;

export const AvatarWrapper = styled.div<StyledAvatarProps>`
  ${(props) => (props.$hide ? "display: none !important;" : "")}
`;

interface TextContainerProps {
  $hasImage: boolean;
}

export const TextContainer = styled.div<TextContainerProps>`
  display: inline-block;
  ${(props) => (props.$hasImage ? `margin-left: ${spacing.xs};` : "")}
`;

export const Text = styled.div`
  ${textLabelStyles}
  color: inherit;
`;
