import * as Styled from "./Badge.styled";

interface BadgeProps {
  textColourName?: string;
  backgroundColourName?: string;
  children?: React.ReactNode;
}

export default function Badge(props: BadgeProps) {
  const { textColourName, backgroundColourName, children, ...rest } = props;

  // $ sign props do not get passed to the underlying react component. They only get consumed by styled-comoponents
  // https://styled-components.com/docs/api#transient-props
  return (
    <Styled.Badge
      $backgroundColourName={backgroundColourName}
      $textColourName={textColourName}
      {...rest}
    >
      {children}
    </Styled.Badge>
  );
}
