import { css } from 'styled-components';
import spacing from 'styles/spacing';
import { textLabelStyles } from 'styles/typography';

export interface StyledTextColourProps {
  $textColourName?: string,
  $backgroundColourName?: string,
}

const getTextColour = (props: StyledTextColourProps) => {
  if (props.$textColourName) {
    return `var(--color-${props.$textColourName})`;
  }
  if (props.$backgroundColourName) {
    return `var(--color-contrast-${props.$backgroundColourName})`;
  }
  return 'var(--color-background)';
}

const getBackgroundColour = (props: StyledTextColourProps) => {
  if (props.$backgroundColourName) {
    return `var(--color-${props.$backgroundColourName})`;
  }
  return 'var(--color-text)';
}

export const baseBadgeStyles = css<StyledTextColourProps>`
  ${textLabelStyles}
  background-color: ${props => getBackgroundColour(props)};
  color: ${props => getTextColour(props)};
  border-radius: 4px;
  padding: ${spacing.xxs} ${spacing.sm};
  text-align: center;
`;
