import { colourNames } from "styles/colours";
import Link from 'next/link'

import * as Styled from "./BadgeLink.styled";

interface BadgeLinkProps {
  to: string;
  textColourName?: string;
  backgroundColourName?: string;
  children?: React.ReactNode;
}

export default function BadgeLink(props: BadgeLinkProps) {
  const {
    to,
    textColourName = colourNames.BACKGROUND,
    backgroundColourName = colourNames.TEXT,
    children = "",
  } = props;

  return (
    <Link href={to} passHref legacyBehavior>
      <Styled.BadgeLink
        $backgroundColourName={backgroundColourName}
        $textColourName={textColourName}
      >
        {children}
      </Styled.BadgeLink>
    </Link>
  );
}
