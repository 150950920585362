import styled from "styled-components";
import { baseBadgeStyles, StyledTextColourProps } from "../CommonStyles";

const getTextHoverColour = (props: StyledTextColourProps) => {
  if (props.$backgroundColourName) {
    return `var(--color-${props.$backgroundColourName})`;
  }
  if (props.$textColourName) {
    return `var(--color-contrast-${props.$textColourName})`;
  }
  return "var(--color-text)";
};

const getBackgroundHoverColour = (props: StyledTextColourProps) => {
  if (props.$backgroundColourName) {
    return `var(--color-contrast-${props.$backgroundColourName})`;
  }
  if (props.$textColourName) {
    return `var(--color-${props.$textColourName})`;
  }
  return "var(--color-background)";
};

export const BadgeLink = styled.a<StyledTextColourProps>`
  ${baseBadgeStyles}
  transition: background 0.2s;
  text-decoration: none;

  :hover {
    background-color: ${(props) => getBackgroundHoverColour(props)};
    color: ${(props) => getTextHoverColour(props)};
  }
`;
