import styled from 'styled-components';
import Image from "next/legacy/image";
import spacing from 'styles/spacing';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.a<{ $imageRatio?: string }>`
  position: relative;
  width: 100%;
  padding-top: ${props => props.$imageRatio};
  margin: 0px;
  overflow: hidden;
  text-decoration: none;
`;


export const StackedImage = styled(Image)`
  object-fit: cover;
  margin: ${spacing.xxs};
  margin-bottom: 0;
  border-radius: 4px;

  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100% - 2 * ${spacing.xxs});
  height: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 1;
  transition: opacity 500ms ease 0s;
`;
