import * as React from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import * as Styled from "./Tooltip.styled";

// It seems the content has to be a button?
export default function Tooltip(props: {
  children: React.ReactNode;
  content: React.ReactNode;
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root
        delayDuration={0}
        open={open}
        onOpenChange={(open) => setOpen(open)}
      >
        <RadixTooltip.Trigger asChild>
          <span style={{ display: "flex" }}>{props.children}</span>
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <Styled.Content sideOffset={5}>
            {props.content}
            <Styled.Arrow />
          </Styled.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}
