import styled from "styled-components";
import { baseBadgeStyles } from "../CommonStyles";

interface StyledBadgeProps {
  $textColourName?: string;
  $backgroundColourName?: string;
}

export const Badge = styled.span<StyledBadgeProps>`
  ${baseBadgeStyles}
`;
