import * as React from 'react';
import { ImageProps } from "next/legacy/image";
import * as Styled from './Avatar.styled';

export interface AvatarProps extends ImageProps {
  className?: string,
}

export default function Avatar(props: AvatarProps) {
  return <Styled.Avatar {...props} />;
};
