import * as React from "react";
import Avatar from "components/atoms/Avatar";
import * as Styled from "./Profile.styled";
import { storyblokLoader } from "utils/storyblok";

interface ProfileProps {
  theme?: "dark" | "light";
  className?: string;
  name?: string;
  children?: React.ReactNode;
  image?: string;
  style?: React.CSSProperties;
}

export default function Profile(props: ProfileProps) {
  const { name, image, children, theme, className, style } = props;

  return (
    <Styled.ProfileContainer theme={theme} className={className} style={style}>
      {image ? (
        <Styled.AvatarWrapper>
          <Avatar
            src={image}
            alt={name}
            width={32}
            height={32}
            loader={storyblokLoader}
          />
        </Styled.AvatarWrapper>
      ) : null}
      <Styled.TextContainer $hasImage={!!image}>
        <Styled.Text>
          <strong>{name}</strong>
        </Styled.Text>
        {children}
      </Styled.TextContainer>
    </Styled.ProfileContainer>
  );
}
