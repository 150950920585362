import PropTypes from "prop-types";
import Head from "next/head";
import logo from "../../../../public/images/atm_logo.png";
import { DEFAULT_SITE_METADATA } from "utils/constants";
import { prependStringIfNotExists } from "utils";

function SEO(props) {
  const {
    title,
    description,
    fullSlug,
    imageURL,
    imageAlt,
    isArticle,
    articlePublishedTime,
    articleModifiedTime,
    articleAuthor,
    tags,
  } = props;

  const metaTitle = `${title || DEFAULT_SITE_METADATA.title}${
    title && ` | ${DEFAULT_SITE_METADATA.title}`
  }`;
  const metaDescription = description || DEFAULT_SITE_METADATA.description;
  let metaImage = imageURL || logo.src;

  if (metaImage && metaImage.startsWith("/")) {
    metaImage = prependStringIfNotExists(
      DEFAULT_SITE_METADATA.siteUrl,
      metaImage
    );
  }
  const metaImageAlt = imageAlt || null;
  const canonicalSlug = fullSlug === '/' ? '' : prependStringIfNotExists('/', fullSlug);
  const canonicalURL = fullSlug
    ? `${DEFAULT_SITE_METADATA.siteUrl}${canonicalSlug}`
    : undefined;
  const metaOGType = isArticle ? "article" : "website";

  const metaArticlePublishedTime = isArticle && articlePublishedTime;
  const metaArticleModifiedTime = isArticle && articleModifiedTime;
  const metaArticleAuthor = isArticle && articleAuthor;
  const metaTags = tags;

  const articleSchema = {
    "@context": "https://schema.org",
    "@type": "Article",
    dateModified: metaArticleModifiedTime,
    datePublished: metaArticlePublishedTime,
    headline: `${metaTitle} | ${metaDescription}`,
    image: metaImage,
  };

  return (
    <Head>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      {canonicalURL ? (
        <link
          rel="canonical"
          href={canonicalURL}
          data-baseprotocol="https:"
          data-basehost="www.attheminute.com"
        />
      ) : null}
      <meta name="p:domain_verify" content="c789b36610c3cdf3bfc73ea26c85c92b" />
      {metaTags && metaTags.length ? (
        <meta name="keywords" content={metaTags.join(",")} />
      ) : null}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      {canonicalURL ? <meta property="og:url" content={canonicalURL} /> : null}
      {metaImage ? <meta property="og:image" content={metaImage} /> : null}
      {metaOGType ? <meta property="og:type" content={metaOGType} /> : null}
      {metaArticlePublishedTime ? (
        <meta
          property="article:published_time"
          content={metaArticlePublishedTime}
        />
      ) : null}
      {metaArticleModifiedTime ? (
        <meta
          property="article:modified_time"
          content={metaArticleModifiedTime}
        />
      ) : null}
      {metaArticleAuthor ? (
        <meta property="article:author" content={metaArticleAuthor} />
      ) : null}

      {metaTags && metaTags.length
        ? metaTags
            .slice(0)
            .reverse()
            .map((tag) => {
              return <meta key={tag} name="article:tag" content={tag} />;
            })
        : null}

      {/* Twitter */}
      <meta name="twitter:card" content={"summary"} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      {metaArticleAuthor ? (
        <meta name="twitter:creator" content={metaArticleAuthor} />
      ) : null}
      {metaImage ? <meta name="twitter:image" content={metaImage} /> : null}
      {metaImageAlt ? (
        <meta name="twitter:image:alt" content={metaImageAlt} />
      ) : null}

      {isArticle && (
        <script type="application/ld+json">
          {JSON.stringify(articleSchema)}
        </script>
      )}
    </Head>
  );
}

SEO.defaultProps = {
  meta: [],
  title: "",
  description: "",
  isArticle: false,
  articlePublishedTime: "", // ISO 8601 string. e.g. 2011-10-05T14:48:00.000Z
  articleModifiedTime: "", // ^
  articleAuthor: "",
  articleMainCategory: "",
  tags: [],
  imageURL: "",
  fullSlug: "",
};
SEO.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  articlePublishedTime: PropTypes.string,
  articleModifiedTime: PropTypes.string,
  articleAuthor: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  imageURL: PropTypes.string,
  imageAlt: PropTypes.string,
  fullSlug: PropTypes.string,
};
export default SEO;
